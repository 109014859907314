<template>
  <Drawer
    draggable
    v-model="isShow"
    width="600"
    scrollable
    title="品牌信息"
    v-if="obj"
  >
    <div class="drawer-container">
      <div class="drawer-content">
        <Row class="text-cell">
          <Col class="title" span="4">品牌ID:</Col>
          <Col class="value" span="20">{{ obj.ID }}</Col>
        </Row>
        <Row class="text-cell">
          <Col class="title" span="4">品牌LOGO:</Col>
          <Col class="value" span="20"
            ><img
              :src="$setImagePrefix(obj.Logo)"
              style="width: 40px; height: 40px; object-fix: cover"
          /></Col>
        </Row>
        <Row class="text-cell">
          <Col class="title" span="4">品牌名称:</Col>
          <Col class="value" span="20">
            <span>{{ obj.Name }}</span>
          </Col>
        </Row>
        <Row class="text-cell">
          <Col class="title" span="4">品牌状态:</Col>
          <Col class="value" span="20">
            <span>{{ obj.Status === 10 ? "启用" : "禁用" }}</span>
          </Col>
        </Row>
        <Row class="text-cell">
          <Col class="title" span="4">上级品牌:</Col>
          <Col class="value" span="20">
            <span>{{ getBrandName(obj) }}</span>
          </Col>
        </Row>
        <!-- <Row class="text-cell">
          <Col class="title"
               span="4">是否显示:</Col>
          <Col class="value"
               span="20">
          <span>{{ obj.IsShow ? '显示' : '隐藏' }}</span>
          </Col>
        </Row> -->
        <Row class="text-cell">
          <Col class="title" span="4">备注:</Col>
          <Col class="value" span="20">
            <span>{{ obj.Remarks }}</span>
          </Col>
        </Row>
        <Row class="text-cell">
          <Col class="title" span="4">排序字段:</Col>
          <Col class="value" span="20">
            <span>{{ obj.Index }}</span>
          </Col>
        </Row>
      </div>
    </div>
  </Drawer>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "brand-drawer",
  data() {
    return {
      isShow: false,
      obj: null,
    };
  },
  created() {
    this.getProductBrandInfo();
  },
  methods: {
    ...mapActions(["getProductBrandInfo"]),
    getBrandName(row) {
      if (row.ParentID) {
        let brand =
          this.ProductBrandInfo &&
          this.ProductBrandInfo.find((it) => it.ID === row.ParentID);
        return brand ? brand.Name : "";
      } else {
        return "";
      }
    },
  },
  watch: {},
  computed: {
    ProductBrandInfo() {
      return this.$store.state.dishes.mallBrands || [];
    },
  },
};
</script>
<style lang="less"></style>

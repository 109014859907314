<template>
  <Modal
    v-model="isShow"
    title="品牌信息"
    width="600"
    :height="1000"
    class-name="i-modal"
    ok-text="保存"
  >
    <Row :gutter="16">
      <Form
        :model="selectedObj"
        label-position="right"
        :rules="validateRules"
        ref="form"
        :label-width="80"
      >
        <Col span="24">
          <FormItem label="品牌ID:" v-if="selectedObj.ID">{{
            selectedObj.ID
          }}</FormItem>
          <FormItem label="品牌LOGO:">
            <i-upload
              :fileKey="uploadType.ProductBrand"
              @onChange="onChange"
              ref="UploadLogo"
              :max="1"
            ></i-upload>
          </FormItem>
          <FormItem label="品牌名称:" prop="Name">
            <Input v-model="selectedObj.Name" />
          </FormItem>
          <FormItem label="品牌状态:" prop="Status">
            <Select v-model="selectedObj.Status">
              <Option :key="10" :value="10">启用</Option>
              <Option :key="20" :value="20">禁用</Option>
            </Select>
          </FormItem>
          <FormItem label="上级品牌" prop="ParentID">
            <Select v-model="selectedObj.ParentID">
              <Option
                v-for="item in brandList"
                :key="item.ID"
                :disabled="item.ID === selectedObj.ID"
                :value="item.ID"
                >{{ item.Name }}</Option
              >
            </Select>
          </FormItem>
          <!-- <FormItem label="是否显示:"
                  prop="IsShowStr">
          <Select v-model="selectedObj.IsShowStr">
            <Option :key="1"
                    :value="1">是</Option>
            <Option :key="0"
                    :value="0">否</Option>
          </Select>
        </FormItem> -->
          <FormItem label="备注:" prop="Remark">
            <Input type="textarea" v-model="selectedObj.Remark" />
          </FormItem>
          <FormItem label="排序字段:" prop="Index">
            <Input v-model="selectedObj.Index" />
          </FormItem>
          <FormItem label="是否显示:" prop="IsShow">
            <Checkbox v-model="selectedObj.IsShow">显示</Checkbox>
          </FormItem>
        </Col>
      </Form>
    </Row>
    <div slot="footer">
      <Button @click="isShow = false">取消</Button>
      <Button type="primary" @click="handleSave" :loading="isSaving">
        保存
      </Button>
    </div>
  </Modal>
</template>

<script>
import api from "@/api";
import bizEnum from "@/libs/bizEnum";
import IUpload from "@/components/upload/index.vue";

export default {
  name: "add-brand",
  components: { IUpload },
  data() {
    return {
      obj: {
        Name: "",
        Status: null,
        IsShowStr: null,
      },
      isEdit: false,
      isSaving: false,
      isShow: false,
      logoFile: [],
      selectedObj: {
        Name: "",
        Status: null,
        IsShowStr: null,
      },
      validateRules: {
        Name: [
          { required: true, message: "品牌名称不能为空", trigger: "blur" },
        ],
        Status: [{ required: true, message: "品牌状态不能为空" }],
        IsShowStr: [{ required: true, message: "是否显示不能为空" }],
      },
    };
  },
  watch: {
    isEdit: function (val) {
      if (val) {
        this.handleTreeItem(this.obj);
      }
    },
    isShow: function (val) {
      if (!val) {
        // 关闭
        this.obj = null;
        this.isEdit = false;
        this.selectedObj = {
          Name: "",
          Status: null,
          IsShowStr: null,
        };
        this.$refs.UploadLogo.uploadFiles = this.logoFile = [];
      } else {
        if (this.isEdit) {
          this.handleTreeItem(this.obj);
        }
      }
    },
  },
  beforeDestroy() {},
  methods: {
    async handleSave() {
      const _this = this;
      _this.isSaving = true;
      _this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (
            _this.logoFile.length &&
            _this.logoFile.filter((f) => !f.success).length
          ) {
            const res = await _this.$refs.UploadLogo.Upload();
            if (res.success.length === 0) {
              _this.isSaving = false;
              return _this.$Message.error("品牌LOGO上传失败");
            } else {
              _this.selectedObj.Logo = res.success[0];
            }
          }
          const result = await api.SaveProductBrand({
            entity: _this.selectedObj,
          });
          _this.$emit("on-save");
          _this.isSaving = false;
          _this.selectedObj = result.Data;
          _this.isShow = false;
          _this.$Message.success("保存成功");
        } else {
          _this.isSaving = false;
          _this.$Message.error("填写信息有误！");
        }
      });
    },
    onChange(opt) {
      this.logoFile = opt.files;
    },
    handleTreeItem(row) {
      row.IsShowStr = row.IsShow ? 1 : 0;
      this.selectedObj = row;
      this.$nextTick(() => {
        if (row.Logo) {
          this.logoFile = this.$refs.UploadLogo.uploadFiles = [
            {
              _key: row.Logo,
              _file: this.$setImagePrefix(row.Logo),
              success: true,
            },
          ];
        }
      });
    },
  },
  computed: {
    uploadType() {
      return bizEnum.UploadType;
    },
    brandList() {
      return this.$parent.$parent.brandList.filter(
        (item) => item.Layer === 0 && item.Status === 10
      );
    },
  },
};
</script>

<style lang="less"></style>

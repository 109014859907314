<template>
  <Layout class="main-container brand-container">
    <!--    搜索块-->
    <Form
      ref="SearchForm"
      class="search-form-container"
      :model="params"
      label-position="left"
      :label-width="70"
    >
      <div class="search-form-container__content">
        <Row :gutter="16">
          <Col span="6">
            <FormItem label="品牌ID:" prop="id">
              <Input v-model="params.id" placeholder="请输入品牌ID" clearable />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="品牌名称:" prop="name">
              <Input
                v-model="params.name"
                placeholder="请输入品牌名称"
                clearable
              />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="启用状态:" prop="status">
              <Select clearable v-model="params.status" placeholder="启用状态">
                <Option value="10">启用</Option>
                <Option value="20">禁用</Option>
              </Select>
            </FormItem>
          </Col>
          <!-- <Col span="6">
          <FormItem label="显示状态:"
                    prop="isShow">
            <Select clearable
                    v-model="showBrand"
                    placeholder="显示状态">
              <Option :value="1">显示</Option>
              <Option :value="0">隐藏</Option>
            </Select>
          </FormItem>
          </Col> -->
        </Row>
      </div>
      <Row type="flex" justify="space-between" align="bottom" class="mt5">
        <Col span="12" style="text-align: left">
          <Button
            type="success"
            icon="md-add-circle"
            v-if="$canAction(['ProductBrand_Edit'])"
            @click="handleOpenAdd"
            >新增</Button
          >
          <Button
            type="warning"
            class="ml2"
            v-if="$canAction(['Sys_Cache_Edit'])"
            @click="$importCache('AllProductBrand', $data)"
            >更新缓存<i>{{ cacheUpdatedTime }}</i></Button
          >
        </Col>
        <Col span="12" style="text-align: right">
          <span
            class="search-form-container__arrow_btn"
            @click="$handleHiddenSearch($el)"
          >
            收起
            <Icon type="ios-arrow-up" />
          </span>
          <Button
            class="ml2 search-form-container__button"
            type="primary"
            ghost
            icon="ios-search"
            @click="handleSearch"
            >搜索</Button
          >
          <Button class="ml2 search-form-container__button" @click="handleReset"
            >重置</Button
          >
        </Col>
      </Row>
    </Form>
    <Table
      class="mt10 main-table"
      :height="tableHeight"
      :loading="loading"
      ref="dataTable"
      :columns="columns"
      :data="brandList.filter((item) => item._show)"
      :row-class-name="getRowClassName"
      @on-sort-change="sortChange"
      size="small"
    >
      <template slot="Logo" slot-scope="scope">
        <img class="logo" :src="$setImagePrefix(scope.row.Logo)" />
      </template>
      <template slot="Status" slot-scope="scope">
        <Tag
          type="border"
          :color="scope.row.Status === 10 ? 'green' : 'volcano'"
          >{{ scope.row.StatusCN }}</Tag
        >
      </template>
      <template slot="IsShow" slot-scope="scope">
        <Tag type="border" :color="scope.row.IsShow ? 'green' : 'orange'">{{
          scope.row.IsShow ? "显示" : "隐藏"
        }}</Tag>
      </template>
      <template slot="Action" slot-scope="{ row }">
        <Button
          size="small"
          class="ml"
          type="primary"
          v-if="$canAction(['ProductBrand_Search'])"
          @click="handleOpenDrawer(row)"
          >查看</Button
        >
        <Button
          size="small"
          class="ml2"
          type="success"
          v-if="$canAction(['ProductBrand_Edit'])"
          @click="handleOpenAdd(row, true)"
          >编辑</Button
        >
        <Button
          size="small"
          class="ml2"
          type="error"
          v-if="$canAction(['ProductBrand_Delete'])"
          @click="handleDeleteBefore(row)"
          >删除</Button
        >
      </template>
    </Table>
    <brand-drawer ref="BrandDrawer" />
    <add-brand ref="AddBrand" @on-save="onUpdated" />
  </Layout>
</template>

<script>
import layout from "@/components/layout/index.vue";
import api from "@/api";
import brandDrawer from "./components/brand-drawer.vue";
import addBrand from "./components/add-brand.vue";
export default {
  name: "ProductBrand",
  components: { brandDrawer, addBrand, layout },
  data() {
    const self = this;
    return {
      showBrand: 3,
      params: {
        id: "",
        name: "",
        isShow: "",
        status: "",
        Page: 1,
        PageSize: 10000,
        SortName: "Index",
        SortOrder: "Asc",
      },
      loading: false,
      cacheUpdatedTime: "",
      tableHeight: 0,
      brandList: [],
      columns: [
        {
          title: "品牌名称",
          key: "Name",
          minWidth: 150,
          render: function (h, params) {
            let icon;
            if (
              !params.row.ParentID &&
              params.row._children &&
              params.row._children.length > 0
            ) {
              icon = (
                <Icon
                  class="icon"
                  type={
                    params.row._isExpand
                      ? "md-arrow-dropdown"
                      : "md-arrow-dropright"
                  }
                  size="20"
                />
              );
            }
            return (
              <div
                onClick={function () {
                  self.expandTable(params.row);
                }}
                class={["expand", { paddingLeft: params.row.ParentID }]}
              >
                {icon}
                <span>{params.row.Name}</span>
              </div>
            );
          },
        },
        { title: "排序", key: "Index", minWidth: 80, sortable: "custom" },
        { title: "品牌LOGO", key: "Logo", minWidth: 120, slot: "Logo" },
        { title: "品牌ID", key: "ID", minWidth: 80 },
        { title: "启用状态", key: "Status", slot: "Status", minWidth: 80 },
        { title: "是否显示", key: "IsShow", slot: "IsShow", minWidth: 80 },
        // { title: '显示状态', key: 'IsShow', slot: 'IsShow', minWidth: 80 }
      ],
    };
  },
  created() {
    if (this.$route.meta.tableActionWidth) {
      this.columns.push({
        title: "操作",
        key: "action",
        slot: "Action",
        align: "center",
        minWidth: this.$route.meta.tableActionWidth,
        maxWidth: this.$route.meta.tableActionWidth,
        fixed: "right",
      });
    }
    this.getProductBrandList();
    this.$getCacheUpdatedTime("AllProductBrand", this);
  },
  mounted() {
    const self = this;
    self.$nextTick(() => {
      self.tableHeight = self.$getTableHeight("main-container");
    });
    window.onresize = function () {
      self.$nextTick(() => {
        self.tableHeight = self.$getTableHeight("main-container");
      });
    };
    document.onkeydown = function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.getProductBrandList();
      }
    };
    this.$bus.$on("refreshPage", () => {
      if (this.$route.name === this.$options.name) {
        this.params && (this.params = this.$options.data().params);
        this.getProductBrandList();
        this.$getCacheUpdatedTime("AllProductBrand", this);
      }
    });
  },
  activated() {
    const self = this;
    document.addEventListener("keydown", function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.getProductBrandList();
      }
    });
  },
  deactivated() {
    document.removeEventListener("keydown", function () {});
  },
  methods: {
    handleOpenDrawer(row) {
      this.$refs.BrandDrawer.obj = row;
      this.$refs.BrandDrawer.isShow = true;
    },
    handleOpenAdd(obj, status) {
      if (status) {
        this.$refs.AddBrand.isEdit = true;
        this.$refs.AddBrand.obj = JSON.parse(JSON.stringify(obj));
      } else {
        this.$refs.AddBrand.obj = { Name: "", Status: null, IsShowStr: null };
      }
      this.$refs.AddBrand.$refs.form.resetFields();
      this.$refs.AddBrand.isShow = true;
    },
    onUpdated() {
      this.$delayLoad(() => {
        this.getProductBrandList();
      });
    },
    handleSearch() {
      this.getProductBrandList();
    },
    // 远程排序
    sortChange(result) {
      this.params.SortName = result.key;
      this.params.SortOrder = this.params.SortOrder === "Desc" ? "Asc" : "Desc";
      this.getProductBrandList();
    },
    handleReset() {
      this.$refs.SearchForm.resetFields();
      this.getProductBrandList();
    },
    getRowClassName(row) {
      return !row.ParentID ? "" : "no-border";
    },
    expandTable(row) {
      if (!row.ParentID) {
        this.brandList.forEach((item) => {
          if (item.ID === row.ID) {
            item._isExpand = !item._isExpand;
          }
          if (item.ParentID === row.ID) {
            item._show = !item._show;
          }
        });
      }
    },
    handleDeleteBefore(obj) {
      const _this = this;
      _this.$Modal.confirm({
        title: "提示",
        closable: true,
        loading: true,
        content: "您确定要删除吗？",
        onOk: function () {
          _this.handleDelete(obj);
        },
        onCancel: () => {
          // _this.$Modal.remove();
        },
      });
    },
    async handleDelete(obj) {
      try {
        await api.DeleteProductBrand({ id: obj.ID });
        this.$Message.success("删除成功");
        this.$Modal.remove();
        this.onUpdated();
      } catch (error) {
        this.$Modal.remove();
      }
    },
    async getProductBrandList() {
      try {
        this.loading = true;
        this.params.isShow = "";
        if (this.showBrand === 1) this.params.isShow = true;
        if (this.showBrand === 0) this.params.isShow = false;
        const res = await api.GetProductBrandList(this.params);
        res.Data.DataList.forEach((item) => {
          item._show = true;
          // 排除 手动筛选条件 与后台返回数据 不一致的情况
          if (this.showBrand === 1 && item.IsShow === false) {
            item._show = false;
          }
          if (this.showBrand === 0 && item.IsShow === true) {
            item._show = false;
          }
        });
        const datalist = res.Data.DataList.filter((item) => !item.ParentID).map(
          (item) => {
            item._children = res.Data.DataList.filter(
              (d) => d.ParentID === item.ID
            );
            return item;
          }
        );
        let result = [];
        datalist.forEach((item) => {
          if (item._children && item._children.length > 0) {
            item._isExpand = true;
            result.push(item);
            result = result.concat(item._children);
          } else {
            item._isExpand = false;
            result.push(item);
          }
        });
        this.brandList = result;
        this.loading = false;
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="less">
.brand-container {
  .logo {
    width: 30px;
    height: 30px;
    background: #ccc;
    object-fit: cover;
  }
  .red {
    background: #f4f4f4;
  }
  .ivu-table-row td {
    border-bottom: none;
    border-top: 1px solid #e8eaec;
  }
  .ivu-table-row.no-border td {
    border-bottom: none;
    border-top: none;
  }
  .ivu-table-row {
    .ivu-table-cell {
      overflow: inherit;
    }
    .expand {
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      .icon {
        position: absolute;
        top: -2px;
        left: 0;
      }
      span {
        padding-left: 30px;
      }
    }
    &.no-border {
      .expand {
        span {
          padding-left: 35px;
        }
        &::after {
          content: "";
          width: 30px;
          height: 1px;
          background: #999;
          position: absolute;
          top: 10px;
          left: 20px;
        }
        &::before {
          content: "";
          width: 1px;
          height: 41px;
          background: #999;
          position: absolute;
          top: -30px;
          left: 20px;
        }
      }
      td:last-child {
        border-bottom: 1px solid #e8eaec;
      }
    }
    .paddingLeft {
      padding-left: 20px;
    }
  }
  .main-table {
    .ivu-table-small {
      display: flex;
      flex-direction: column;
      .ivu-table-overflowY {
        flex: 1;
      }
      .ivu-table-fixed-right {
        height: 100%;
        display: flex;
        flex-direction: column;
        .ivu-table-fixed-body {
          flex: 1;
        }
      }
    }
  }
}
</style>
